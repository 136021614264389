<template>
  <b-container fluid="xs">
    <vue-element-loading :active="isloading" spinner="ring" background-color="rgba(255,255,255,0.6)" size="400" color="#F0F"/>
    <div v-show="items.length">
    <b-table-simple hover small caption-top responsive="xs">
      <b-thead head-variant="light">
        <b-tr>
          <b-th>agentid</b-th>
          <b-th>agentnick</b-th>
          <b-th>facemoney</b-th>
          <b-th v-b-tooltip.hover title="4 digits, used by agent user, target active account input needed.">agentcardid</b-th>
          <b-th v-b-tooltip.hover title="8 digits, used by end user, self active code. Can be printed on one Card and sell it.">globalcardid</b-th>
          <b-th>invalid</b-th>
          <b-th>usedtime</b-th>
          <b-th>useduser</b-th>
          <b-th>usednick</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item, index) in items" :key="index">
          <b-td>{{item.proxyuser}}</b-td>
          <b-td>{{item.proxynick}}</b-td>
          <b-td>
            {{item.facemoney}}
            <b-icon variant="success" icon="x-circle-fill" @click="remove(item)" v-b-tooltip.hover title="remove this record."></b-icon>
          </b-td>
          <b-td>{{item.scardid}}</b-td>
          <b-td>{{item.gcardid}}</b-td>
          <b-td>{{item.invalid}}</b-td>
          <b-td>{{item.usedtime}}</b-td>
          <b-td>{{item.chargeuser}}</b-td>
          <b-td>{{item.chargenick}}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    </div>
    <div v-show="items.length==0" class="pl-5 pr-5">
      <b-card class="xnshadow" header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h4 class="mb-0"><b-icon icon="gem"></b-icon> virtual cards</h4>
        </template>
        <b-card-text>
          <p>
            no data found<br/><br/>
           </p>
        </b-card-text>
      </b-card>
    </div>
    <b-form inline class="mt-4 ml-4" @submit.stop.prevent>
      <b-button-group class="mr-4 mb-4">
        <b-button variant="success" @click="fetchprev" :disabled="pageid==0">{{$t('message.btn_prevpage')}}</b-button>
        <b-button v-if="pageid>0" variant="outline-success" :disabled="true" >{{pageid+1}}</b-button>
        <b-button variant="success" @click="fetchNext" :disabled="pageid==newpagestart.length">{{$t('message.btn_nextpage')}}</b-button>
      </b-button-group>
      <b-button-group class="mr-4 mb-4" v-if="proxy && items.length>0">
        <b-button variant="info" @click="download">{{$t('message.btn_vcard_download')}}</b-button>
      </b-button-group>
    </b-form>
    <b-form v-if="iscreate==1" class="pt-4 pl-4 pr-4 pb-4 container xnpanel" @submit.stop.prevent>
      <b-jumbotron>
        <p>1. virtualCard short number can be multi printed on one paper and used by agent user.</p>
        <p>2. virtualCard long number only be printed on one card and sold to end user.</p>
        <p>3. One Agent user only can have 2000 vCard max.</p>
        <p>4. Max 1000 vCards can be generated at one request.</p>
        <p>5. On "user" manage page, click the card icon to visit ones cards.</p>
      </b-jumbotron>
      <b-input-group size="lg" class="mb-4">
        <b-input-group-prepend>
          <b-input-group-text>
          <b-icon icon="person"></b-icon>AgentUser:
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input type="text" v-model="proxy" disabled></b-form-input>
      </b-input-group>
      <b-input-group size="lg" class="mb-4">
        <b-input-group-prepend>
          <b-input-group-text>
          <b-icon icon="currency-dollar"></b-icon>facemoney:
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input type="number" v-model="facemoney" required></b-form-input>
      </b-input-group>
      <b-input-group size="lg" class="mb-4">
        <b-input-group-prepend>
          <b-input-group-text>
          <b-icon icon="grid3x3-gap"></b-icon>vCardMounts:
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input type="number" v-model="mount" required></b-form-input>
      </b-input-group>
      <b-input-group size="lg" class="mb-4">
        <b-input-group-prepend>
          <b-input-group-text>
          <b-icon icon="clock"></b-icon>TimeoutDays:
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input type="number" v-model="tmoutdays" required></b-form-input>
      </b-input-group>
      <b-form-group>
        <b-button size="lg" block variant="success" @click="createnow">Create</b-button>
      </b-form-group>
    </b-form>
    <b-modal v-model="modalshow" no-close-on-backdrop no-close-on-esc hide-header ok-only :ok-title="$t('message.btn_ok')"><span v-html="modalmsg"></span></b-modal>
  </b-container>
</template>

<script>
  export default {
    name: 'admprepays',
    mounted() {
      this.fetchData();
    },
    data() {
      return {
        iscreate: this.$route.query.create,
        proxy: this.$route.query.proxy,
        tmoutdays: 90,
        facemoney: 10,
        mount: 200,
        isloading: false,
        modalshow:false,
        modalmsg: '',
        maskenabled: false,
        items: [],
        pageid:0,
        newpagestart:[]
      };
    },
    methods: {
      fetchprev() {
        this.pageid = this.pageid-1;
        this.fetchData();
      },
      fetchNext() {
        this.pageid = this.pageid+1;
        this.fetchData();
      },
      async fetchData() {
        this.isloading = true;
        let w = window.innerWidth;
        let h = window.innerHeight;
        let lotoken = localStorage.getItem('token');
        let theapi = 'token='+lotoken+'&pagesize='+this.$pagesize+'&w='+w+'&h='+h;
        if (this.proxy) {
          theapi = theapi + '&proxy='+this.proxy;
        }
        if (this.pageid>0 && this.newpagestart[this.pageid-1]) {
          theapi = theapi + '&last='+encodeURIComponent(JSON.stringify(this.newpagestart[this.pageid-1]));
        }
        let axresp = await this.axios.post('/admvcards?tm='+new Date().getTime(), theapi);
        this.isloading = false;
        if (axresp.status==200) {
          let axdata = axresp.data;
          this.items = axdata.Items;
          if (axdata.LastEvaluatedKey) {
            if (this.newpagestart.length<=this.pageid) {
              this.newpagestart.push(axdata.LastEvaluatedKey);
            } else {
              this.newpagestart[this.pageid] = axdata.LastEvaluatedKey;
            }
          }
        } else {
          this.items = [];
        }
      },
      async remove(itm) {
        let lotoken = localStorage.getItem('token');
        let theapi = 'token='+lotoken+'&removetm='+itm.cretime+'&removeusr='+itm.proxyuser;
        await this.axios.post('/admvcardremove?tm='+new Date().getTime(), theapi);
        this.fetchData();
      },
      async download() {
        let lotoken = localStorage.getItem('token');
        let theapi = 'token='+lotoken+'&proxy='+this.proxy;
        let axresp = await this.axios.post('/admvcarddown?tm='+new Date().getTime(), theapi);
        if (axresp.status==200) {
          window.location.href = axresp.data;
        }
      },
      async createnow() {
        if ( this.facemoney <= 0 ) {
          this.modalmsg = 'facemoney is not valid!';
          this.modalshow = true;
        } else if ( this.mount <= 0 ) {
          this.modalmsg = 'mount is not valid!';
          this.modalshow = true;
        } else if ( this.mount > 1000 ) {
          this.modalmsg = 'max number should be 1000';
          this.modalshow = true;
        } else if ( this.tmoutdays <= 5 ) {
          this.modalmsg = 'timeout days is not valid!';
          this.modalshow = true;
        } else {
          this.iscreate = 0;
          let lotoken = localStorage.getItem('token');
          let theapi = 'token='+lotoken+'&proxy='+this.proxy+'&facemoney='+this.facemoney+'&tmoutdays='+this.tmoutdays+'&mount='+this.mount;
          let axresp = await this.axios.post('/admvcardgen?tm='+new Date().getTime(), theapi);
          if (axresp.status==200) {
            this.fetchData();
          }
        }
      },
    }
  }
</script>
